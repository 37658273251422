<template>
   <div>
       <Slider />
      <ContactHolder />

   </div>
</template>

<script>
export default {
    name: 'About',
    data () {
       return {
            page: 'about',
       };
    },
    components: {
      Slider: () => import('./slider'),
      ContactHolder: () => import('./contactHolder'),

    },

}
</script>

<style>

</style>